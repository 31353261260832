<template>
  <div>
    <v-tabs background-color="background lighten-1">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :to="'/locations-and-groups/locations'">{{_is50c?'Organizations': 'Locations'}}</v-tab>
      <v-tab :to="'/locations-and-groups/groups'">{{_is50c?'Organization Groups': 'Location Groups'}}</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    if (!this.$route.query.location_id && !this.$route.query.location_group_id) {
      this.$router.push('/locations-and-groups/locations')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
